define("discourse/plugins/discourse-category-lockdown/discourse/initializers/extend-for-lockdown", ["exports", "discourse/lib/plugin-api", "discourse/lib/url", "discourse/raw-views/topic-status", "discourse-common/utils/decorators", "discourse-common/lib/helpers"], function (_exports, _pluginApi, _url, _topicStatus, _decorators, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "discourse-category-lockdown";
  function initializeLockdown(api) {
    var _obj, _dec, _obj2;
    // Intercept any HTTP 402 (Payment Required) responses for topics
    // And redirect the client accordingly
    api.modifyClass("model:post-stream", {
      pluginId: PLUGIN_ID,
      errorLoading(result) {
        const status = result.jqXHR.status;
        let response = result.jqXHR.responseJSON;
        if (status === 402) {
          let redirectURL = response.redirect_url || this.siteSettings.category_lockdown_redirect_url;
          const external = redirectURL.startsWith("http");
          if (external) {
            // Use location.replace so that the user can go back in one click
            document.location.replace(redirectURL);
          } else {
            // Handle the redirect inside ember
            return _url.default.handleURL(redirectURL, {
              replaceURL: true
            });
          }
        }
        return this._super();
      }
    });
    api.modifyClass("component:topic-list-item", (_obj = {
      pluginId: PLUGIN_ID,
      unboundClassNames() {
        let classNames = this._super(...arguments) || "";
        if (this.get("topic.is_locked_down")) {
          classNames += " locked-down";
        }
        return classNames;
      }
    }, (_applyDecoratedDescriptor(_obj, "unboundClassNames", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "unboundClassNames"), _obj)), _obj));

    // Add an icon next to locked-down topics
    _topicStatus.default.reopen((_dec = (0, _decorators.default)(), (_obj2 = {
      statuses() {
        const results = this._super();
        if (this.topic.is_locked_down) {
          results.push({
            openTag: "span",
            closeTag: "span",
            title: I18n.t("lockdown.topic_is_locked_down"),
            icon: this.siteSettings.category_lockdown_list_icon
          });
        }
        return results;
      }
    }, (_applyDecoratedDescriptor(_obj2, "statuses", [_dec], Object.getOwnPropertyDescriptor(_obj2, "statuses"), _obj2)), _obj2)));

    // Warning: "route:docs-index" may not be found if the 'discourse-docs' plugin is not installed. This is expected and harmless.
    api.modifyClass("route:docs-index", {
      pluginId: PLUGIN_ID,
      model(params, transition) {
        return this._super(params).catch(error => {
          let response = error.jqXHR.responseJSON;
          const status = error.jqXHR.status;
          if (status === 402) {
            // abort the transition to prevent momentary error
            // from being displayed
            transition.abort();
            let redirectURL = response.redirect_url || this.siteSettings.category_lockdown_redirect_url;
            const external = redirectURL.startsWith("http");
            if (external) {
              document.location.href = redirectURL;
            } else {
              // Handle the redirect inside ember
              return _url.default.handleURL(redirectURL, {
                replaceURL: true
              });
            }
          }
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "apply-lockdown",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", initializeLockdown);
    }
  };
});