define("discourse/plugins/discourse-category-lockdown/discourse/pre-initializers/extend-category-for-lockdown", ["exports", "discourse/models/category", "@ember/object/computed"], function (_exports, _category, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-category-for-lockdown",
    before: "inject-discourse-objects",
    initialize() {
      _category.default.reopen({
        lockdown_enabled: Ember.computed("custom_fields.lockdown_enabled", {
          get(fieldName) {
            return Ember.get(this.custom_fields, fieldName) === "true";
          }
        }),
        redirect_url: (0, _computed.alias)('custom_fields.redirect_url')
      });
    }
  };
});