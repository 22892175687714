define("discourse/plugins/discourse-category-lockdown/discourse/connectors/category-custom-settings/lockdown-settings", ["exports", "discourse/models/group"], function (_exports, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("groupFinder", term => {
        return _group.default.findAll({
          term: term,
          ignore_automatic: false
        });
      });
    },
    actions: {
      onChangeSetting(value) {
        this.set("category.custom_fields.lockdown_enabled", value ? "true" : "false");
      }
    }
  };
});